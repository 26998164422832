@import 'mixins';


::selection{
    background-color: $main-blue;
    color: white;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

h3{
    text-align: center;
    font-size: 36px;
    font-weight: 300;
}

.order-button{
    cursor: pointer;
}

.site-header{
    width: 100%;
    height: 100px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__mobile-button{
        display: none;
        svg{
            path{
                &:nth-child(1){
                    transition: 0.3s;
                }
                &:nth-child(2){
                    transition: 0.3s;
                }
                &:nth-child(3){
                    transition: 0.3s;
                }
            }
        }
    }
    
    &__mobile-button_active{
        svg{
            @include flexRow(center);
            path{
                &:nth-child(1){
                    opacity: 0;
                }
                &:nth-child(2){
                    transform: rotateZ(90deg) translateY(-27px) ;
                }
                &:nth-child(3){
                    opacity: 0;
                }
            }
        }
    }

    &__logo-block{
        width: 200px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(center);
    }

    &__logo{
        max-width: 80%;
        max-height: 80%;
    }

    &__nav{
        // background-color: pink;
    }

    &__menu{
        // background-color: plum;
        @include flexRow(space-between);
        align-items: center;
        list-style: none;
        height: 100%;
    }

    &__menu-item{
        height: 100%;
        margin: 0px 20px;
        // background-color: orange;
        &:first-child{
            margin-left: 0px;
        }
        @include flexColumn(center);
        align-items: center;
    }

    &__menu-link{
        text-decoration: none;
        font-size: 20px;
        color: $main-dark;

        &:hover{
            color: $main-blue;
        }
    }

    &__order-button{
        // width: 150px;
        // height: 50px;
        font-size: 20px;
        background-color: $main-blue;
        color: white;
        text-decoration: none;
        padding: 15px;
        border-radius: 8px;
        transition: 0.3s;
        box-shadow: 0px 5px 0px 0px darken($main-blue, 10%);
        
        &:hover{
            box-shadow: 0px 0px 0px 0px darken($main-blue, 10%);
            margin-top: 10px;
        }
    }
}

.main-section{
    width: 100%;
    height: 600px;
    // background-color: red;
    background-image: url(./../src/img/main.jpg);
    background-attachment: fixed;
    background-position: 50%;
    position: relative;

    &__cover{
        width: 100%;
        height: 100%;
        background-color: $main-dark;
        position: absolute;
        opacity: 50%;
    }

    &__slider{
        width: 100%;
        min-height: 100%;
        // background-color: yellow;
        position: absolute;
        z-index: 1;
        // @include flexRow(center);
    }
    
    &__slide{
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(center);
    }

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
    }

    &__slide-header{
        // background-color: orange;
        margin-top: 100px;
        font-size: 50px;
        color: white;
        max-width: 850px;
        font-weight: 300;
    }

    &__text{
        max-width: 650px;
        // background-color: orange;
        font-size: 30px;
        font-weight: 200;
        color: white;
        margin-top: 10px;
    }

    &__order-button{
        width: 300px;
        height: 50px;
        background-color: $main-blue;
        margin-top: 20px;
        @include flexColumn(center);
        align-items: center;
        color: white;
        text-decoration: none;
        font-size: 20px;
        border-radius: 5px;
        transition: 0.3s;
        box-shadow: 0px 5px 0px 0px darken($main-blue, 10%);
        
        &:hover{
            box-shadow: 0px 0px 0px 0px darken($main-blue, 10%);
            margin-top: 30px;
        }
    }

}

.pluses-section{
    width: 100%;
    background-color: lighten($main-blue, 10%);
    @include flexRow(center);
    
    &__container{
        max-width: 1200px;
        width: 100%;
        // height: 100%;
        // background-color: yellow;
        @include flexRow(center);
        align-items: center;
        flex-wrap: wrap;
    }
    
    &__plus-block{
        min-height: 200px;
        width: 300px;
        // height: 100%;
        // background-color: pink;
        margin: 0px 0px;
        @include flexColumn(flex-start);
        align-items: center;
        cursor: pointer;

        &:first-child{
            margin-left: 0px;
        }

        &:last-child{
            margin-right: 0px;
        }

        &:hover{
            .pluses-section__plus-circle{
                transform: scale(1.3);
            }
        }
    }

    &__plus-circle{
        width: 15px;
        height: 15px;
        border: 10px solid white;
        background-color: transparent;
        // color: white;
        border-radius: 50px;
        margin-top: 40px;
        transition: 0.3s;
    }

    &__plus-header{
        width: 100%;
        // background-color: plum;
        color: white;
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
    }

    &__plus-text{
        width: 70%;
        color: white;
        font-weight: 300px;
        // background-color: plum;
        text-align: center;
        margin-top: 10px;
    }
}

.categories-section{
    background-color: darken(white,2%);
    // margin-top: 100px;
    padding: 100px 0px 50px 0px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__categories-container{
        width: 100%;
        max-height: 440px;
        margin-top: 50px;
        // background-color: pink;
        overflow: hidden;
        @include flexRow(center);
        flex-wrap: wrap;
        transition: 0.3s;
    }

    &__categories-container_active{
        max-height: 1000px;
        transition: 0.3s;
    }

    &__category{
        width: 220px;
        height: 220px;
        // padding: 20px;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: center;
        text-decoration: none;

        &:hover{
            background-color: lighten($main-blue,0%);
            border-radius: 5px;
            .categories-section__name{
                color: white;
            }
        }
    }

    &__img-block{
        width: 100%;
        height: 170px;
        @include flexRow(center);
        align-items: center;
        // background-color: orange;
    }

    &__img{
        max-width: 70%;
        max-height: 70%;
    }

    &__name{
        // height: 20px;
        // margin-top: 10px;
        // margin-bottom: 10px;
        // background-color: orange;
        color: $main-dark;
        font-weight: 300;
        font-size: 20px;
        text-align: center;
    }

    &__more-buttons-block{
        width: 100%;
        height: 60px;
        // background-color: pink;
        margin-top: 20px;
        @include flexRow(center);
        align-items: center;
    }

    &__more-button{
        width: 150px;
        height: 50px;
        background-color: $main-blue;
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        color: white;
        text-decoration: none;
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 0px 5px 0px 0px darken($main-blue, 10%);
        
        &:active{
            box-shadow: 0px 0px 0px 0px darken($main-blue, 10%);
            margin-top: 10px;
        }
    }

    #show-less{
        display: none;
    }
}

.place-section,
.way-section{
    // background-color: red;
    padding: 50px 0px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__place-img-container{
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        margin-top: 30px;
    }

    &__place-img{
        max-width: 50%;
        object-fit: contain;
        max-height: 300px;
        margin: 0px 20px 20px 20px;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }
    }

    &__link{
        margin-top: 20px;
        padding: 5px;
        background-color: $main-blue;
        color: white;
        text-decoration: none;
        border-radius: 3px;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }
    }
}

.price-section{
    width: 100%;
    // height: 300px;
    // background-color: red;
    padding: 50px 0px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__prices-container{
        margin-top: 50px;
        width: 100%;
        // height: 200px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__price-block{
        height: 100%;
        width: 300px;
        margin: 15px;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: center;
        text-decoration: none;

        &:first-child{
            margin-left: 0px;
        }

        &:last-child{
            margin-right: 0px;
        }

    }

    &__name{
        // background-color: orange;
        width: 100%;
        text-align: center;
        font-size: 36px;
        margin-top: 20px;
        color: $main-dark;
    }
    
    &__text{
        color: $main-dark;
        width: 100%;
        text-align: center;
        margin-top: 10px;
        // background-color: orange;
        font-size: 20px;
        font-weight: 300;
        min-height: 60px;
    }

    &__price{
        color: $main-blue;
        width: 100%;
        text-align: center;
        font-size: 36px;
    }

    &__extra-text-block{
        @include flexRow(center);
        align-items: center;
        margin-top: 30px;
    }

    &__extra-text{
        text-align: center;
        color: lighten(black, 50%);
    }
}

.brands-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    margin-top: 30px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__brands-container{
        max-height: 350px;
        overflow: hidden;
        overflow-y: scroll;
        width: 100%;
        margin-top: 50px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;

        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main-blue;
            border-radius: 100px;
        }
    }

    &__brand{
        width: 150px;
        height: 50px;
        box-shadow: 0px 0px 0px 1px $main-blue;
        border-radius: 10px 10px 0px 10px ;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: $main-blue;
        transition: 0.3s;
        margin: 10px;
        &:hover{
            transform: scale(1.1);
            box-shadow: 0px 0px 3px 0px $main-blue;
            border-radius: 5px;
        }
    }
}

.areas-section{
    margin-top: 30px;
    padding: 50px 0px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    background-image: url(./../src/img/areas.jpg);
    @include flexRow(center);
    background-size: cover;
    background-position: 51% 49%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    
    
    &__cover{
        // padding: 50px 0px;
        margin-top: -50px;
        width: 100%;
        height: 100%;
        opacity: 50%;
        background-color: $main-blue;
        position: absolute;
    }

    &__container{
        max-width: 1080px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
        flex-wrap: wrap;
        // position: absolute;
        z-index: 1;
    }

    &__buttons-block{
        max-width: 400px;
        // width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__text{
        width: 100%;
        // background-color: plum;
        // letter-spacing: 1px;
        color: white;
        font-size: 19px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    &__toggle{
        width: 200px;
        height: 50px;
        // background-color: plum;
        margin-top: 10px;
        text-decoration: none;
        border-radius: 5px;
        background-color: $main-blue;
        @include flexRow(center);
        align-items: center;
        color: white;
        font-size: 18px;
        transition: 0.3s;
        cursor: pointer;
        &:hover{
            color: $main-dark;
        }
    }

    &__order-button{
        width: 250px;
        height: 60px;
        background-color: $main-blue;
        margin-top: 20px;
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        color: white;
        text-decoration: none;
        font-size: 20px;
        transition: 0.3s;
        box-shadow: 0px 5px 0px 0px darken($main-blue, 10%);
        
        &:hover{
            box-shadow: 0px 0px 0px 0px darken($main-blue, 10%);
            margin-top: 30px;
        }
        svg{
            transition: 0.3s;
            fill: white;
            margin-left: 10px;
        }
    }

    &__areas-container{
        padding: 10px 0px 10px 0px;
        max-width: 600px;
        width: 100%;
        max-height: 400px;
        height: 100%;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        // overflow: hidden;
        overflow-y: scroll;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0px 0px 5px 0px white;
        // display: none;

        &::-webkit-scrollbar{
            width: 3px;
            // background-color: red;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main-blue;
            // width: 3px;
            border-radius: 5px;
        }

        &:nth-child(3){
            display: none;
        }
    }

    &__area{
        width: 150px;
        height: 50px;
        text-align: center;
        @include flexRow(center);
        align-items: center;
        text-decoration: none;
        color: white;
        border-radius: 5px;
        background-color: $main-blue;
        margin: 15px 15px 15px 15px;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
            color: white;
            box-shadow: 0px 0px 5px 0px $main-blue;
        }
    }
}

.reviews-section{
    padding: 30px 0px;
    width: 100%;
    background-color: darken(white, 2%);
    // background-color: red;
    @include flexRow(center);

    &__container{
        width: 1080px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        font-size: 36px;
        text-align: center;
    }

    &__slider{
        margin-top: 30px;
        width: 100%;
        // height: 500px;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__slider-nav{
        width: 50px;
        height: 100%;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
    }
    
    &__nav-button{
        width: 40px;
        height: 40px;
        // background-color: orange;
        transition: 0.3s;
        cursor: pointer;

        svg{
            width: 100%;
            height: 100%;
            path{
                fill: $main-blue;
            }
        }

        &:hover{
            transform: scale(1.1);
        }

        &:active{
            transition: 0s;
            transform: scale(0.9);
        }
    }

    &__slides-container{
        width: 90%;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex);
        align-items: center;
    }

    &__slide{
        // background-color: aqua;
        min-height: 350px;
        &:not(:first-child){
            display: none;
        }
    }

    &__img-block{
        margin-top: 30px;
        width: 100%;
        height: 150px;
        // background-color: orange;
        @include flexRow(center);
    }

    &__img{
        width: 150px;
        border-radius: 100px;
        height: 150px;
    }

    &__reviewer-name{
        width: 100%;
        // height: 30px;
        // background-color: orange;
        margin-top: 7px;
        text-align: center;
        color: $main-dark;
    }

    &__text{
        width: 100%;
        // height: 30px;
        // background-color: orange;
        margin-top: 20px;
        text-align: center;
        // color: $main-dark;
    }
}

.site-footer{
    padding: 30px 0px;
    width: 100%;
    // height: 300px;
    // background-color: red;
    background-color: $main-dark;
    @include flexRow(center);

    &__container{
        max-width: 1080px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__contacts-block{
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }
    
    &__contacts-container{
        padding: 0px 15px 0px 15px;
        @include flexColumn(flex-start);
        // background-color: rgb(133, 45, 133);
    }
    
    &__contact{
        // width: 100%;
        padding: 15px 10px;
        // height: 100%;
        // background-color: orange;
        text-decoration: none;
        color: white;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }
        svg{
            margin-right: 15px;
            path,line,polyline,polygon{
                stroke: white;
            }
        }
    }

    &__map-block{
        max-width: 700px;
        width: 100%;
        min-height: 300px;
        // max-height: 300px;
        // height: 100%;
        // background-color: plum;
        border-radius: 5px;
        overflow: hidden;
    }

    &__extra-block{
        width: 100%;
        min-height: 50px;
        // background-color: pink;
        margin-top: 15px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__extra-link{
        height: 100%;
        // background-color: plum;
        @include flexColumn(center);
        text-decoration: none;
        color: white;
        margin: 10px 10px 10px 10px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
            color: $main-blue;
        }
    }
}

.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__form{
        padding: 30px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 5px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 20px;
        height: 20px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 25px);
        bottom: calc(100% - 25px);
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__header{
        font-size: 20px;
        width: 200px;
    }

    &__user-input{
        margin-top: 20px;
        width: 200px;
        height: 30px;
        border: 1px solid $main-blue;
        border-radius: 5px;
        padding: 0px 0px 0px 5px;
        outline: none;
        font-size: 18px;
        font-weight: 300;
    }

    &__button{
        width: 210px;
        height: 40px;
        background-color: $main-dark;
        margin-top: 15px;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $main-blue;
        }
    }

    &__policy-text{
        width: 210px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: lightgrey;
    }
}

.up-button{
    width: 100px;
    height: 100px;
    border-radius: 100px 0px 0px 100px;
    background-color: $main-blue;
    box-shadow: 0px 0px 0px 5px white;
    @include flexColumn(center);
    align-items: center;
    // position: fixed;
    position: fixed;
    top: calc(95% - 100px);
    left: calc(100% - 100px);
    z-index: 100;
    display: none;

    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        background-color: white;
        // box-shadow: 0px 0px 0px 1px $main-dark inset;
        @include flexColumn(center);
        align-items: center;
    }
    svg{
        background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        fill: rgb(0, 102, 153);
        path{
        }
        width: 40px;
        height: 40px;
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

@keyframes modalCallAnim {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(10deg);}
    100%{transform: rotate(0deg);}
}

.call-button{
    box-shadow: 0px 0px 0px 5px white;
    width: 100px;
    height: 100px;
    border-radius: 0px 100px 100px 0px;
    background-color: $main-blue;
    @include flexColumn(center);
    align-items: center;
    // position: fixed;
    position: fixed;
    top: calc(95% - 100px);
    right: calc(100% - 100px);
    z-index: 100;
    display: none;
    
    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        background-color: white;
        @include flexColumn(center);
        align-items: center;
    }
    
    svg{
        // display: none;
        animation: modalCallAnim 1s infinite;
        background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        width: 40px;
        height: 40px;
        path{

            fill: $main-blue;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            animation-play-state: paused;
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

.error-block{
    width: 100%;
    height: 90vh;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    text-align: center;

    &__container{
        @include flexColumn(center);
        // background-color: yellow;
        height: 100%;
    }

    &__message{
        font-size: 36px;
    }

    &__link{
        color: $main-blue;
        font-size: 36px;
    }
}

.categories-block{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.678);
    position: absolute;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__container{
        padding: 10px;
        max-width: 1200px;
        width: 100%;
        height: 600px;
        background-color: white;
        border-radius: 5px;
        position: relative;
        @include flexRow(center);
        flex-wrap: wrap;
        overflow: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main-blue;
        }
    }

    &__close-button{
        position: fixed;
        background-color: white;
        border-radius: 0px 0px 0px 15px;
        width: 50px;
        height: 50px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 50px);
        bottom: calc(100% - 50px);
        cursor: pointer;
        svg{
            width: 100%;
            height: 100%;
            // box-shadow: 0px 0px 0px 2px red;
            transition: 0.3s;
            fill: $main-blue;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__category{
        width: 150px;
        height: 50px;
        box-shadow: 0px 0px 0px 1px $main-blue;
        border-radius: 10px 10px 0px 10px;
        text-decoration: none;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        margin: 10px;
        color: $main-blue;
        font-size: 20px;
        // position: absolute;
        &:hover{
            border-radius: 10px;
            transition: 0.3s;
            // box-shadow: 0px 0px 3px 0px $main-blue;
            transform: scale(1.1);
            color: white;
            background-color: $main-blue;
            // margin-top: 5px;
        }
    }


}

@media (max-width: 1200px){
    .site-header{
        // display: none;
        // background-color: red;
        // height: 100px;
        height: 140px;
        // height: unset;
        // padding: 20px 0px 20px 0px;
        @include flexRow(center);
        align-items: center;
        position: relative;

        &__mobile-button{
            display: flex;
            order: 2;
            margin-top: 10px;
            box-shadow: 0px 0px 0px 2px $main-blue;
            height: 40px;
            @include flexColumn(center);
            border-radius: 5px;

            svg{
                path{
                    stroke: $main-dark;
                }
            }
        }

        &__container{
            // background-color: yellow;
            @include flexColumn(flex-start);
            align-items: center;
            z-index: 3;
            height: unset;
            position: absolute;
            // padding: 0px 100px;
        }
        
        &__nav{
            @include flexColumn(flex-start);
            width: 100%;
            align-items: center;
            background-color: white;
            position: absolute;
            margin-top: 120px;
            height: unset;
        }


        &__menu{
            z-index: 9999;
            // display: none;
            // position: absolute;
            // background-color: white;
            // height: unset;
            width: 100%;
            @include flexColumn(flex-start);
            align-items: center;
            padding: 0px 0px 20px 0px;
            display: none;
        }

        &__menu-item{
            // background-color: red;
            width: 100%;
            @include flexRow(center);
            margin: 0px 0px 10px 0px !important;
            
        }
        
        &__menu-link{
            @include flexRow(center);
            width: 100%;
            // background-color: yellow;

            &::after{
                display: none;
            }

            &:hover{
                &::after{
                    display: none;
                }
            }
        }
    }
    .bx-viewport {
        height: 700px !important;
    }

    .bx-wrapper{
        margin-bottom: 0px;
    }

    .main-section{
        height: unset;
        // min-height: 700px;
        &__container{
            // background-color: red;
            align-items: center;
        }

        &__slide-header{
            font-size: 3em;
            text-align: center;
            padding: 0px 10px;
        }

        &__text{
            text-align: center;
        }
    }

    .areas-section{
        &__container{
            // background-color: red;
            @include flexRow(center);
            align-items: center;
        }
        &__text{
            width: 90%;
            // background-color: yellow;
        }
        &__areas-container{
            margin-top: 20px;
        }
    }
}